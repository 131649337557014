@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Updock&display=swap");
*,
*:after,
*:before {
    box-sizing: border-box;
}

body {
    font-family: "Cormorant Garamond", serif;
    background-color: #29282b;
    color: #ccc;
}

h1 {
    font-family: "Updock", cursive;
    font-size: 5.8rem;
    color: #d94f5c;
    margin-top: 5vh;
}

h2 {
    font-size: 3rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

h2:after {
    display: block;
    margin: 0 auto;
    content: " ";
    width: 25%;
    border-bottom: #ccc solid 1px;
}

.list-container {
    margin: 1em auto;
    padding: 0.3em 0.6em;
}

.list-container:after,
.list-container:before {
    display: table;
    content: " ";
    line-height: 0;
    font-size: 0;
    clear: both;
}

h3 {
    position: relative;
    font-weight: 700;
    margin: 0.7em 0 1em 0;
}

h3:after,
h3:before {
    display: table;
    content: " ";
    line-height: 0;
    font-size: 0;
    clear: both;
}

h3 span {
    float: left;
    width: 10%;
    text-align: center;
    display: block;
}

h3 span:first-child {
    width: 50%;
    text-align: left;
}

h3 .dots {
    width: 40%;
    border-bottom: 2px dotted #999;
    margin-top: 0.8em;
}